import React, { useContext, useState } from 'react';

import { Box } from '@mui/material';

import { navigate } from 'gatsby';
import { DataContext } from '../../../../providers/DataProvider';
import NavBarButton from '../../button/NavBarButton';

const AdditionalButtonsBar = () => {
  const {
    pathname,
    homeDirectories,
    handleDrawerToggle,
    mobileOpen,
    setShowDemoDialog,
  } = useContext(DataContext);

  const isHomepage = homeDirectories.includes(pathname);
  const isContactPage = pathname.includes('contactus');
  const isFAQsPage = pathname.includes('faqs');
  const [requestDemoBackgroundColor, setRequestDemoBackgroundColor] = useState({
    backgroundColor: null,
    variant: null,
  });

  const christmasPageButtonColor = pathname.includes('christmas')
    ? 'christmas'
    : null;

  const handleScrollClick = () => {
    const element = document.getElementById('form');

    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleButtonClick = () => {
    navigate('/demorequest');
    // setShowDemoDialog(true);

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (pathname === '/') {
  //         if (!entries[0].isIntersecting) {
  //           setRequestDemoBackgroundColor({
  //             backgroundColor: null,
  //             variant: null,
  //           });
  //         } else {
  //           setRequestDemoBackgroundColor({
  //             backgroundColor: 'neutral',
  //             variant: 'outlined',
  //           });
  //         }
  //       }
  //     },
  //     {
  //       rootMargin: '0px',
  //       threshold: 0.2,
  //     }
  //   );

  //   const targetElement = document.getElementById('heroSection');
  //   if (targetElement) {
  //     observer.observe(targetElement);
  //   }
  //   // if (pathname !== '/' && observer) {
  //   //   observer.disconnect();
  //   // }
  // }, [pathname]);

  const buttons = [
    // {
    //   id: 'contactUs',
    //   label: 'Contact Us',
    //   styles: { backgroundColor: christmasPageButtonColor },
    //   onClick: handleScrollClick,
    //   hrefKey: !isHomepage && !isContactPage && !isFAQsPage ? 'contactus' : '',
    //   startIcon: null,
    // },
    {
      id: 'demoRequest',
      label: 'Request a Demo',
      styles: requestDemoBackgroundColor,
      startIcon: null,
      onClick: handleButtonClick,
    },
  ];

  return (
    <Box
      sx={{
        minWidth: '400px',
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      {buttons.map((buttonObj) => {
        const { id, label, styles, startIcon, onClick, hrefKey } = buttonObj;
        const { backgroundColor = '', variant = '' } = styles;
        return (
          <Box
            key={id}
            sx={{
              marginLeft: 3,
              width: '150px',
            }}
          >
            <NavBarButton
              buttonName={label}
              backgroundColor={backgroundColor}
              variant={variant}
              startIcon={startIcon}
              onClick={onClick}
              hrefKey={hrefKey}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default AdditionalButtonsBar;
