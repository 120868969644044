import { Typography } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import CustomGridCarousel from '../../common/carousel/CustomGridCarousel';
import CustomFanGoCard from '../../common/fanGoCard/CustomFanGoCard';
import styles from '../../common/styling/styles';

const responsiveSizes = [
  {
    breakpoint: 1200,
    cols: 3,
    rows: 3,
  },
  {
    breakpoint: 950,
    cols: 3,
    rows: 2,
  },
  {
    breakpoint: 650,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const DemoRequestWhyChooseFanGo = ({ itemList }) => {
  const cards = [];
  itemList.forEach((item, index) => {
    const { title, text, image, url } = item;
    const { asset, altText = '' } = image;
    const img = asset?.gatsbyImageData;

    const card = (
      <CustomFanGoCard
        minHeight="440px"
        cardMaxWidth="330px"
        key={uuidv4()}
        title={title}
        image={img}
        altText={altText}
        text={text}
        fullImageBorderRadius
        imgPaddingTop={15}
        titleTextAlign="center"
        contentTextAlign="center"
        containerShadow="0 3px 30px rgb(140, 152, 164, 0.8)"
        link={url}
      />
    );
    cards.push(card);
  });

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <Typography variant="h2" align="center" color={styles.deepGreen}>
          Why should you choose
        </Typography>
        <Typography variant="h2" align="center" color={styles.deepGreen}>
          FanGo?
        </Typography>
      </div>
      <CustomGridCarousel
        itemsArray={cards}
        responsiveSizes={responsiveSizes}
        paddingBottom={2}
        speed={500}
      />
    </>
  );
};

export default DemoRequestWhyChooseFanGo;
