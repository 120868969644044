import React, { useContext } from 'react';

import { Button } from '@mui/material';

import { navigate } from 'gatsby';
import { DataContext } from '../../../providers/DataProvider';

const DemoButton = ({
  buttonName = '',
  boxShadow = null,
  fontSize = 15,
  buttonWidth = null,
  variant = null,
  startIcon = null,
  color = null,
  lineHeight = null,
  currentPage = '',
}) => {
  const { setShowDemoDialog, handleDrawerToggle, mobileOpen, pathname } =
    useContext(DataContext);

  let christmasPageButtonColor = null;
  if (currentPage.includes('christmas')) {
    christmasPageButtonColor = pathname.includes('christmas')
      ? 'christmas'
      : null;
  }

  const buttonStyle = {
    textTransform: 'initial !important',
    boxShadow: boxShadow || '0 3px 10px rgb(0 0 0 / 0.2)',
    fontSize: fontSize,
    width: buttonWidth || null,
    lineHeight: lineHeight,
  };

  const handleButtonClick = () => {
    navigate('/demorequest');
    // setShowDemoDialog(true);

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <Button
      variant={variant || 'contained'}
      color={christmasPageButtonColor || color || 'secondary'}
      size="large"
      sx={buttonStyle}
      startIcon={startIcon || undefined}
      onClick={handleButtonClick}
    >
      {buttonName}
    </Button>
  );
};

export default DemoButton;
