import React, { useContext } from 'react';

import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { navigate } from 'gatsby';
import { DataContext } from '../../../../../providers/DataProvider';
import GreenFanGoLinkButton from '../../../../common/button/GreenFanGoLinkButton';
import { FanGoTextProcessor } from '../../../../common/portableText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {},
    gridItemCenterContent: {
      margin: 'auto!important',
    },
  })
);

const BasicTitleSubTitle = ({
  title,
  subTitle,
  subTitlePlainText = false,
  titleColor = '#ffffff',
  subTitleColor = '#ffffff',
  titleTextAlign = 'center',
  subTitleTextAlign = 'center',
  font = 'Domine',
  containerHeight = '600px',
  hideSubTitleOnMobile = false,
  showRequestADemo = false,
  largeSubTitleSize = false,
  mobileScreen = false,
  titleRowSpacing,
  titlePaddingBottom,
}) => {
  const classes = useStyles();
  const { setShowDemoDialog } = useContext(DataContext);

  return (
    <div
      style={{
        width: '100%',
        height: containerHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // paddingTop:
        //   Number(containerHeight.substring(0, containerHeight.length - 2)) *
        //   0.1,
        paddingTop: '65px',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          textAlign: titleTextAlign,
          color: titleColor,
          maxWidth: mobileScreen ? '95%' : '70%',
          rowGap: titleRowSpacing ? titleRowSpacing : 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h1"
          fontFamily={font}
          style={{
            textAlign: 'center',
            paddingBottom: titlePaddingBottom ? titlePaddingBottom : 0,
          }}
        >
          {title}
        </Typography>
        {subTitlePlainText && (
          <Typography
            lineHeight={2}
            fontFamily={font}
            variant={largeSubTitleSize ? 'h4' : null}
          >
            {subTitle}
          </Typography>
        )}
        {!subTitlePlainText && !hideSubTitleOnMobile && (
          <FanGoTextProcessor
            color={subTitleColor}
            text={subTitle}
            textAlign={subTitleTextAlign}
            largeSubTitleSize={largeSubTitleSize}
          />
        )}
        {showRequestADemo && (
          <GreenFanGoLinkButton
            onClick={() => navigate('/demorequest')}
            buttonText="Request a Demo"
          />
        )}
      </div>
    </div>
  );
};

export default BasicTitleSubTitle;
