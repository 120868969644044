import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgContainer: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: 80,
      },
    },
    text: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  })
);

const ReverseImageAndText = ({
  imagesAndParagraphs,
  smallScreen,
  imgGridItemSize = 7,
  textGridItemSize = 5,
  shadow = true,
  maxHeight = null,
  paddingLeft = 4,
  paddingRight = 4,
  showImageBackgroundEffect = false,
  showTextShadows = false,
}) => {
  const classes = useStyles();
  const showBackgroundShadow = useMediaQuery(`(min-width: 1250px)`);
  return (
    <>
      {imagesAndParagraphs.map((imgAndParagraph, index) => {
        const { image, paragraphs } = imgAndParagraph;
        const { asset, altText } = image;
        const { gatsbyImageData } = asset;

        // Reverse determines whether the current value is odd or even, causing a row or row-reverse flex to places images and text left/right accordingly.
        const reverse = (index + 1) % 2 ? true : false;
        const uid = uuidv4();
        return (
          <Grid
            key={uid}
            container
            sx={{
              display: 'flex',
              flexDirection: reverse ? 'row' : 'row-reverse',
            }}
          >
            <Grid
              item
              xs={12}
              md={imgGridItemSize}
              paddingLeft={smallScreen ? 0 : !reverse && paddingLeft}
              paddingRight={smallScreen ? 0 : reverse && paddingRight}
              marginBottom={smallScreen ? 3 : 5}
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {shadow && showBackgroundShadow && showImageBackgroundEffect && (
                <div
                  style={{
                    backgroundColor: 'rgb(210, 210, 210)',
                    position: 'absolute',
                    width: '95%',
                    height: '95%',
                    top: -10,
                    left: reverse ? -15 : 45,
                  }}
                />
              )}
              <GatsbyImage
                image={gatsbyImageData}
                alt={altText || ''}
                objectFit="contain"
                style={{
                  marginTop: '5px',
                  boxShadow: shadow ? '0 3px 10px rgb(0 0 0 / 0.2)' : '',
                  maxHeight: maxHeight,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={textGridItemSize}
              marginBottom={5}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1,
                justifyContent: 'center',
              }}
            >
              {paragraphs.map((paragraph) => {
                const { text, title } = paragraph;
                return (
                  <div
                    key={title}
                    style={{
                      boxShadow:
                        shadow && showTextShadows
                          ? '0 3px 10px rgb(0 0 0 / 0.2)'
                          : '',
                      padding: 20,
                      borderRadius: 7,
                    }}
                  >
                    <Typography variant="h4" className={classes.text}>
                      {title}
                    </Typography>
                    <Typography className={classes.text}>{text}</Typography>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ReverseImageAndText;
