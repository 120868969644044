import React from 'react';

import { Link } from '@mui/material';
import { getLink } from '../../../utils/getLink';
import CardContent from './CardContent';

const ClientTestimonialCard = ({
  clientName,
  testimony,
  jobTitle,
  clientLogoImageData,
  clientLogoImageAltText,
  clientPropertyImageUrl,
  companyWebsiteURL,
  minHeight = '',
  maxWidth = '',
  mobileScreen = false,
  internalLink = false,
}) => {
  return (
    <div
      style={{
        height: '100%',
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {!internalLink && (
        <a
          href={companyWebsiteURL}
          target="_blank"
          style={{ height: '100%', textDecoration: 'none' }}
        >
          <CardContent
            clientName={clientName}
            testimony={testimony}
            jobTitle={jobTitle}
            clientLogoImageData={clientLogoImageData}
            clientLogoImageAltText={clientLogoImageAltText}
            clientPropertyImageUrl={clientPropertyImageUrl}
            minHeight={minHeight}
            maxWidth={maxWidth}
            mobileScreen={mobileScreen}
          />
        </a>
      )}
      {internalLink && (
        <Link href={getLink({ key: companyWebsiteURL })} underline="none">
          <CardContent
            clientName={clientName}
            testimony={testimony}
            jobTitle={jobTitle}
            clientLogoImageData={clientLogoImageData}
            clientLogoImageAltText={clientLogoImageAltText}
            clientPropertyImageUrl={clientPropertyImageUrl}
            minHeight={minHeight}
            maxWidth={maxWidth}
            mobileScreen={mobileScreen}
          />
        </Link>
      )}
    </div>
  );
};

export default ClientTestimonialCard;
