import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import Body from '../../common/body/Body';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      width: '80%',
      margin: 'auto',
    },
  })
);

const NoneAlternatingMeetingRoomPoint = ({ data }) => {
  const classes = useStyles();
  const { title, text, images } = data;

  const { asset, altText } = images;
  const selectImage = asset.gatsbyImageData;

  return (
    <Body paddingY={styles.allTwo}>
      <Box>
        <SubText
          variant="subtitle1"
          fontColor={styles.grey}
          value={<FanGoTextProcessor text={text} textAlign="center" />}
        />

        <div className={classes.imageContainer}>
          <GatsbyImage
            image={selectImage}
            alt={altText || ''}
            objectFit="contain"
          />
        </div>
      </Box>
    </Body>
  );
};

export default NoneAlternatingMeetingRoomPoint;
