import { graphql } from 'gatsby';
import React from 'react';

import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import FanGoBody from '../components/common/body/FanGoBody';
import { FanGoTextProcessor } from '../components/common/portableText';
import StandardSEO from '../components/common/seo/StandardSEO';
import MobileScreen from '../components/PageComps/CommonComps/deviceScreens/MobileScreen';
import ScrollingImageBanner from '../components/PageComps/CommonComps/TrustedBrandIcons/ScrollingImageBanner';
import ClientTestimonialsSection from '../components/PageComps/DemoRequestPageComps/ClientTestimonialsSection';
import DemoRequestTopSection from '../components/PageComps/DemoRequestPageComps/DemoRequestTopSection';
import DemoRequestWhyChooseFanGo from '../components/PageComps/DemoRequestPageComps/DemoRequestWhyChooseFanGo';
import TitleSubtitleIconList from '../components/PageComps/DemoRequestPageComps/TitleSubtitleIconList';
import PricingSection from '../components/PageComps/PricingPageComps/christmasPricingComponents/PricingSection';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navSim: {
      height: '70px',
      backgroundColor: theme.palette.primary.main,
    },
    horizontalCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    textContainer: {
      width: '70%',
      margin: 'auto',
    },
  })
);

const DemoRequestPage = ({ data }) => {
  const classes = useStyles();
  const { sanityDemoRequestPage, sanityHomePage } = data;
  const {
    titleAndSubtitle,
    pageOneImage,
    softwareBenefitsOne,
    clientTestimonials,
    paragraphOne,
    sectionTwo,
    softwareBenefitsTwo,
    softwareBenefitsThree,
  } = sanityDemoRequestPage;
  const { title: sectionTwoTitle, _rawBody: sectionTwoSubtitle } = sectionTwo;

  let howDidYouHearAboutUsOptions = [];
  const { pageHead } = sanityHomePage;
  if (pageHead) {
    const { referralOptions } = pageHead;
    if (referralOptions) {
      const { referralOptionsList } = referralOptions;
      howDidYouHearAboutUsOptions = referralOptionsList;
    }
  }

  const demoForm = sanityHomePage.pageHead.demoForm;
  const demoFormAndReferralOptions = {
    referralOptions: howDidYouHearAboutUsOptions,
    ...demoForm,
  };

  const mobileScreen = useMediaQuery(`(max-width: 600px)`);
  return (
    <>
      <div className={classes.navSim} />
      <DemoRequestTopSection
        titleSubtitle={titleAndSubtitle}
        sectionImage={pageOneImage}
        sectionOneList={softwareBenefitsOne}
        mobileScreen={mobileScreen}
        formData={demoFormAndReferralOptions}
      />
      <FanGoBody>
        <ScrollingImageBanner />
      </FanGoBody>
      <ClientTestimonialsSection clientTestimonials={clientTestimonials} />
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          background: `linear-gradient(10deg, #2d348a 30%, #141855 90%)`,
        }}
      >
        <div className={classes.textContainer}>
          <Typography variant="h3" align="center" color="white">
            {paragraphOne.title}
          </Typography>
          <FanGoTextProcessor
            color="white"
            textAlign="center"
            text={paragraphOne._rawBody}
          />
        </div>
      </div>
      <FanGoBody>
        <Grid
          container
          sx={{ flexDirection: mobileScreen ? 'column-reverse' : 'row' }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.horizontalCenter}
            sx={{
              paddingRight: mobileScreen ? 0 : 3,
              paddingTop: mobileScreen ? 3 : 0,
            }}
          >
            <MobileScreen hideHome />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TitleSubtitleIconList
              title={sectionTwoTitle}
              subTitle={sectionTwoSubtitle}
              list={softwareBenefitsTwo}
              iconName="tick"
              mobileScreen={mobileScreen}
            />
          </Grid>
        </Grid>
      </FanGoBody>

      <div
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          background: `linear-gradient(10deg, #2d348a 30%, #141855 90%)`,
        }}
      >
        <PricingSection />
      </div>
      <FanGoBody>
        <DemoRequestWhyChooseFanGo itemList={softwareBenefitsThree} />
      </FanGoBody>
    </>
  );
};

export default DemoRequestPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityDemoRequestPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityDemoRequestPage {
      seo {
        title
        description
        keywords
        name
        type
      }
      titleAndSubtitle {
        title
        _rawBody
      }
      softwareBenefitsOne {
        text
        title
      }
      paragraphOne {
        title
        _rawBody
      }
      pageOneImage {
        altText
        asset {
          gatsbyImageData
        }
      }
      sectionTwo {
        title
        _rawBody
      }
      softwareBenefitsTwo {
        title
        text
      }
      softwareBenefitsThree {
        title
        text
        url
        image {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [WEBP]
              height: 200
              width: 300
            )
          }
        }
      }
      clientTestimonials {
        clientTestimony {
          jobTitle
          testimony
          clientName
          companyWebsiteURL
        }
        clientLogoImage {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 300, formats: [WEBP])
          }
        }
        clientPropertyImage {
          asset {
            url
          }
        }
      }
    }

    sanityHomePage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
      pageHead {
        demoForm {
          title
          subTitle
          demoFormFields {
            label
            select
            showField
            key
            multiline
            required
            fullWidth
            _key
            _type
            types {
              label
              value
            }
          }
        }
        referralOptions {
          referralOptionsList {
            optionName
            showOption
          }
        }
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
