import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Autocomplete, Box, Grid, TextField } from '@mui/material';

import styles from '../common/styling/styles';
import FormButton from './FormButton';
import FormCover from './FormCover';
import ReferralOptionsComp from './ReferralOptionsComp';

const formBodyStyle = { py: 1.5 };

const DemoForm = ({
  isLoading,
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  formData,
}) => {
  const { demoFormFields, buttonName, referralOptions } = formData;

  const [fields, setFields] = useState(demoFormFields);

  useEffect(() => {
    const { productOfInterest = [] } = values;

    const fieldsCopy = cloneDeep(fields);

    const foundOther = productOfInterest.find(
      (product) => product.value === 'Others'
    );

    const foundOthersField = fieldsCopy.find((field) => field.key === 'others');

    if (foundOthersField) {
      const { showField } = foundOthersField;
      if (!!foundOther !== !!showField) {
        foundOthersField.showField = !!foundOther;

        setFields(fieldsCopy);
      }
    }
  }, [values.productOfInterest]);

  return (
    <Box sx={{ position: 'relative' }}>
      <form onSubmit={handleSubmit}>
        <Box sx={formBodyStyle}>
          <Box sx={{ flexGrow: 1, pb: 3 }}>
            <Grid container spacing={2}>
              {fields.map((field) => {
                const {
                  key,
                  label,
                  required,
                  fullWidth,
                  multiline,
                  select,
                  showField,
                } = field;

                if (showField) {
                  if (key === 'howDidYouHearAboutUs') {
                    return (
                      <ReferralOptionsComp
                        errors={errors}
                        fullWidth={fullWidth}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        fieldKey={key}
                        key={key}
                        label={label}
                        referralOptions={referralOptions}
                        required={required}
                        touched={touched}
                        values={values}
                      />
                    );
                  }
                  if (!select)
                    return (
                      <Grid key={key} item xs={12} md={!fullWidth ? 6 : 12}>
                        <TextField
                          key={key}
                          id={key}
                          autoComplete="on"
                          variant="outlined"
                          // variant="filled"
                          label={required ? `${label} *` : label}
                          value={values[key]}
                          placeholder={label}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!(touched[key] && errors[key])}
                          helperText={touched[key] && errors[key]}
                          fullWidth
                          multiline={multiline}
                          rows={3}
                          size="small"
                          InputLabelProps={styles.labelStyle}
                          InputProps={styles.inputStyle}
                        />
                      </Grid>
                    );

                  return (
                    <Grid key={key} item xs={12} md={!fullWidth ? 6 : 12}>
                      <Autocomplete
                        size="small"
                        multiple
                        limitTags={4}
                        disablePortal
                        disableCloseOnSelect
                        key={key}
                        id={key}
                        options={field.types}
                        disableListWrap
                        onChange={(e, newValue) => {
                          handleChange({
                            target: { id: key, value: newValue },
                          });
                        }}
                        onBlur={() => handleBlur(key)}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[]}
                        value={values[key]}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            // variant="filled"
                            autoComplete="on"
                            label={required ? `${label} *` : label}
                            placeholder={label}
                            error={!!(touched[key] && errors[key])}
                            helperText={touched[key] && errors[key]}
                            InputLabelProps={styles.labelStyle}
                            InputProps={{
                              ...params.InputProps,
                              ...styles.inputStyle,
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>

          <FormButton
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            buttonName={buttonName}
          />
        </Box>
      </form>
      {isLoading && <FormCover />}
    </Box>
  );
};

export default DemoForm;
