import { Box, Typography } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const CardContent = ({
  clientName,
  testimony,
  jobTitle,
  clientLogoImageData,
  clientLogoImageAltText,
  clientPropertyImageUrl,
  minHeight = '',
  maxWidth = '',
  mobileScreen = false,
}) => {
  return (
    <Box
      sx={{
        boxShadow: '0 3px 10px rgb(100 100 100 / 0.2)',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        pb: 2,
        height: '100%',
        minHeight: minHeight && mobileScreen ? minHeight : undefined,
        maxWidth: maxWidth ? maxWidth : undefined,
      }}
      key={clientName}
    >
      <Box
        sx={{
          backgroundImage: `url(${clientPropertyImageUrl})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '300px',
          width: '100%',
          borderRadius: '8px 8px 0 0',
          pb: 2,
        }}
      />
      <Box
        sx={{
          pl: 2,
          pr: 2,
        }}
      >
        <Typography
          variant="body2"
          fontStyle={'italic'}
          textAlign={'center'}
          color={'black'}
        >
          "{testimony}"
        </Typography>
      </Box>
      <Box sx={{ pl: 2, pr: 2, marginTop: 'auto' }}>
        <Typography fontWeight={'bold'} textAlign={'center'} color={'black'}>
          {clientName}
        </Typography>
        <Typography textAlign={'center'} color={'black'}>
          {jobTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          borderBottom: '1px dashed',
          borderColor: 'black',
          width: '80%',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100px',
        }}
      >
        <GatsbyImage
          image={clientLogoImageData}
          alt={clientLogoImageAltText || ''}
          style={{ width: '100%', height: '100%' }}
          objectFit="contain"
        />
      </Box>
    </Box>
  );
};

export default CardContent;
