import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    images: {
      animation: '$swipe 120s linear infinite',
      paddingBottom: 5,
    },
    '@keyframes swipe': {
      '0%': {
        transform: 'translateX(0%)',
      },
      '100%': {
        transform: 'translateX(-100%)',
      },
    },
    img: {
      width: '200px',
      height: '120px',
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        height: '90px',
      },
    },
  })
);

const ScrollingImageBanner = ({ bannerWidth = '1200px' }) => {
  const response = useStaticQuery(graphql`
    {
      sanityCommonContent(language: { eq: "en" }) {
        trustedBy {
          images {
            altText
            asset {
              gatsbyImageData(width: 200, formats: [WEBP])
            }
          }
        }
      }
    }
  `);

  const classes = useStyles();

  const { sanityCommonContent } = response;

  const { trustedBy } = sanityCommonContent;

  const { images } = trustedBy;

  return (
    <div style={{ backgroundColor: 'white', width: '100%', marginTop: 10 }}>
      <div style={{ overflow: 'hidden' }}>
        <div style={{ display: 'flex' }}>
          <div className={classes.images} style={{ display: 'flex' }}>
            {images.map((imgObj) => {
              const { altText, asset } = imgObj;
              const { gatsbyImageData } = asset;
              return (
                <div
                  className={classes.img}
                  key={altText}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 5,
                    marginLeft: 10,
                    marginRight: 10,
                    borderRadius: 10,
                    maxWidth: '200px',
                    maxHeight: '120px',
                  }}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={altText || ''}
                    objectFit="contain"
                    loading="eager"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className={classes.images} style={{ display: 'flex' }}>
            {images.map((imgObj) => {
              const { altText, asset } = imgObj;
              const { gatsbyImageData } = asset;
              return (
                <div
                  className={classes.img}
                  key={altText}
                  style={{
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 5,
                    marginLeft: 10,
                    marginRight: 10,
                    borderRadius: 10,
                    width: '200px',
                    height: '120px',
                  }}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={altText || ''}
                    objectFit="contain"
                    loading="eager"
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollingImageBanner;

// import { graphql, useStaticQuery } from 'gatsby';
// import { GatsbyImage } from 'gatsby-plugin-image';
// import React from 'react';

// import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
// import { createStyles, makeStyles } from '@mui/styles';

// import Body from '../../../common/body/Body';
// import { FanGoGridCarousel } from '../../../common/carousel';
// import CustomGridCarousel from '../../../common/carousel/CustomGridCarousel';
// import styles from '../../../common/styling/styles';
// import { MainText } from '../../../common/texts';
// import Animation from '../animation/Animation';
// import FanGoSlider from './FanGoSlider';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     brandLogoContainer: {
//       width: '200px',
//       height: '120px',
//       boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
//       borderRadius: '10px',
//       padding: '5px',
//       marginTop: '10px',
//       marginBottom: '10px',
//       [theme.breakpoints.down(519)]: {
//         width: '140px',
//         height: '84px',
//       },
//     },
//     bannerWrapper: {
//       animation: '$marquee 10s linear infinite',
//     },
//     wrapper: {},

//     '@keyframes marquee': {
//       '0%': {
//         transform: 'translateX(0)',
//       },
//       '100%': {
//         transform: 'translateX(-100%)',
//       },
//     },
//     img: {
//       display: 'flex',
//       width: '150px',
//       margin: '5px',
//       backgroundColor: 'blue',
//     },
//   })
// );

// const ScrollingImageBanner = () => {
//   const response = useStaticQuery(graphql`
//     {
//       sanityCommonContent(language: { eq: "en" }) {
//         trustedBy {
//           images {
//             altText
//             asset {
//               gatsbyImageData(placeholder: BLURRED, width: 200, formats: [WEBP])
//             }
//           }
//         }
//       }
//     }
//   `);

//   const classes = useStyles();

//   const { sanityCommonContent } = response;

//   const { trustedBy } = sanityCommonContent;

//   const { images } = trustedBy;

//   return (
//     <>
//       <Grid
//         container
//         wrap="nowrap"
//         //   overflow="hidden"
//         className={classes.bannerWrapper}
//       >
//         {images.map((imgObj) => {
//           const { altText, asset } = imgObj;
//           const { gatsbyImageData } = asset;
//           return (
//             <Grid item xs={2} sx={{ minWidth: '150px' }}>
//               <GatsbyImage
//                 image={gatsbyImageData}
//                 alt={altText || ''}
//                 objectFit="contain"
//                 style={{
//                   width: '100%',
//                   height: '100%',
//                 }}
//               />
//             </Grid>
//           );
//         })}
//       </Grid>
//     </>
//   );
// };

// export default ScrollingImageBanner;
