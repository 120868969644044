import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';

import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (props) => ({
      justifyContent: 'center',
      borderRadius: 10,
      boxShadow: props.containerShadow
        ? props.containerShadow
        : '0 3px 10px rgb(140, 152, 164, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      transition: 'top 0.3s ease',
      top: props.isHovered ? '-10px' : '0px',
      height: '100%',
      maxWidth: props.cardMaxWidth,
      margin: 10,
      backgroundColor: props.cardBackgroundColor,
      minWidth: props.minCardWidth,
      maxHeight: props.maxHeight,
    }),
    imageContainer: (props) => ({
      borderRadius: props.fullImageBorderRadius ? '8px' : '8px 8px 0 0',
      paddingTop: props.imgPaddingTop,
      // height: '300px',
      // minHeight: '300px',
      display: 'flex',
      justifyContent: 'center',
    }),
    textContainer: (props) => ({
      paddingLeft: 10,
      paddingRight: 10,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'auto',
      rowGap: 10,
    }),
    actionContainer: { marginTop: 'auto' },
    text: {
      color: 'black',
    },
    titleText: {
      fontWeight: 'bold',
      pb: 1,
      color: '#141854',
      paddingTop: 15,
    },
    learnMoreText: {
      color: 'rgb(45, 155, 131)',
      fontSize: '1rem',
    },
  })
);

const DividerLine = (
  <Box
    sx={{
      mt: 1,
      height: '1px',
      backgroundColor: '#D3D3D3',
      width: '100%',
    }}
  />
);

const CustomFanGoCard = ({
  title,
  image,
  altText,
  text,
  link = '',
  externalLink = false,
  minCardWidth = null,
  cardMaxWidth = '300px',
  titleFontWeight = null,
  minHeight = null,
  maxHeight = null,
  imgPaddingTop = 0,
  cardBackgroundColor = null,
  titleTextAlign = null,
  contentTextAlign = null,
  fullImageBorderRadius = false,
  containerShadow = '',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const props = {
    isHovered,
    cardMaxWidth,
    imgPaddingTop,
    cardBackgroundColor,
    minCardWidth,
    maxHeight,
    fullImageBorderRadius,
    containerShadow,
  };

  const classes = useStyles(props);
  return (
    <div
      style={{
        // paddingTop: '0px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '20px',
        minHeight: minHeight,
      }}
    >
      {link && (
        <a
          href={link}
          target={externalLink ? '_blank' : null}
          style={{
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <div
            className={classes.container}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box className={classes.imageContainer}>
              <GatsbyImage
                image={image}
                alt={altText || ''}
                objectFit="contain"
                style={{
                  borderRadius: fullImageBorderRadius ? '8px' : '8px 8px 0 0',
                }}
              />
            </Box>
            <Box className={classes.textContainer}>
              <Typography
                variant="h5"
                className={classes.titleText}
                fontWeight={titleFontWeight}
                textAlign={titleTextAlign}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                textAlign={contentTextAlign}
                className={classes.text}
              >
                {text}
              </Typography>
              {link && (
                <div className={classes.actionContainer}>
                  {DividerLine}
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold' }}
                    className={classes.learnMoreText}
                  >
                    Learn More
                  </Typography>
                </div>
              )}
            </Box>
          </div>
        </a>
      )}
      {!link && (
        <div
          className={classes.container}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box className={classes.imageContainer}>
            <GatsbyImage
              image={image}
              alt={altText || ''}
              objectFit="contain"
              style={{
                borderRadius: fullImageBorderRadius ? '8px' : '8px 8px 0 0',
              }}
            />
          </Box>
          <Box className={classes.textContainer}>
            <Typography
              variant="h5"
              className={classes.titleText}
              fontWeight={titleFontWeight}
              textAlign={titleTextAlign}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              textAlign={contentTextAlign}
              className={classes.text}
            >
              {text}
            </Typography>
            {link && (
              <div className={classes.actionContainer}>
                {DividerLine}
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 'bold' }}
                  className={classes.learnMoreText}
                >
                  Learn More
                </Typography>
              </div>
            )}
          </Box>
        </div>
      )}
    </div>
  );
};

export default CustomFanGoCard;
