import React from 'react';

import ClientTestimonialCard from './ClientTestimonialCard';

const getTestimonialCards = ({
  minHeight = '',
  maxWidth = '',
  mobileScreen = false,
  testimonials,
  internalLink = false,
}) => {
  const cardArray = [];
  testimonials.forEach((item) => {
    const { clientLogoImage, clientPropertyImage, clientTestimony } = item;

    let clientPropertyImageUrl = '';
    let clientPropertyImageAltText = '';

    let clientLogoImageData = '';
    let clientLogoImageAltText = '';

    if (clientPropertyImage) {
      const { asset, altText } = clientPropertyImage;
      const { url } = asset;
      clientPropertyImageUrl = url;
      clientPropertyImageAltText = altText;
    }

    if (clientLogoImage) {
      const { asset, altText } = clientLogoImage;
      const { gatsbyImageData } = asset;

      clientLogoImageData = gatsbyImageData;
      clientLogoImageAltText = altText;
    }

    const { clientName, company, jobTitle, testimony, companyWebsiteURL } =
      clientTestimony;

    cardArray.push(
      <ClientTestimonialCard
        clientName={clientName}
        jobTitle={jobTitle}
        testimony={testimony}
        clientPropertyImageUrl={clientPropertyImageUrl}
        clientLogoImageData={clientLogoImageData}
        clientLogoImageAltText={clientLogoImageAltText}
        companyWebsiteURL={companyWebsiteURL}
        minHeight={minHeight}
        maxWidth={maxWidth}
        mobileScreen={mobileScreen}
        internalLink={internalLink}
      />
    );
  });

  return cardArray;
};

export default getTestimonialCards;
