import React from 'react';

import { Stack } from '@mui/material';

import NavBar from '../navBar/NavBar';
import AdditionalButtonsBar from './AdditionalButtonsBar';

const navBarAndButtonContainerStyle = {
  width: { xs: 0, sm: 0, md: '100%', lg: '100%' },
  display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex' },
  alignItems: 'center',
  height: '100%',
  marginLeft: 5,
};

const LargeScreenConfig = () => {
  return (
    <Stack direction="row" sx={navBarAndButtonContainerStyle}>
      <NavBar />
      <AdditionalButtonsBar />
    </Stack>
  );
};

export default LargeScreenConfig;
