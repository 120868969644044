import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import FanGoIconGenerator from '../../common/icon/FanGoIconGenerator';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontalContainer: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: theme.spacing(1),
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2),
    },
    verticallySpacesContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2),
    },
  })
);

const TitleSubtitleIconList = ({
  title,
  subTitle,
  list,
  iconName,
  mobileScreen,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.verticallySpacesContainer}>
      <Typography
        variant="h3"
        color={styles.deepGreen}
        textAlign={mobileScreen ? 'center' : 'left'}
      >
        {title}
      </Typography>
      <FanGoTextProcessor
        text={subTitle}
        textAlign={mobileScreen ? 'center' : 'left'}
        margin={0}
      />
      <div className={classes.optionsContainer}>
        {list.map((listItem) => {
          const { text, title } = listItem;
          return (
            <div className={classes.horizontalContainer} key={uuidv4()}>
              <FanGoIconGenerator iconName={iconName} color="#2D9B83" />
              <Typography>
                <b>{title}:</b> {text}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TitleSubtitleIconList;
