import { Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import FanGoBody from '../../common/body/FanGoBody';
import CustomGridCarousel from '../../common/carousel/CustomGridCarousel';
import styles from '../../common/styling/styles';
import getTestimonialCards from '../../common/testimonials/getTestimonialCards';

const responsiveSizes = [
  {
    breakpoint: 800,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 500,
    cols: 1,
    rows: 1,
  },
];

const ClientTestimonialsSection = ({ clientTestimonials }) => {
  const setSinglecolumnCardHeight = useMediaQuery(`(max-width: 800px)`);
  const cards = getTestimonialCards({
    testimonials: clientTestimonials,
    // minHeight: '765px',
    maxWidth: '500px',
    mobileScreen: setSinglecolumnCardHeight,
    internalLink: true,
  });
  return (
    <FanGoBody paddingTop="0px">
      <div style={{ marginBottom: '24px' }}>
        <Typography variant="h2" align="center" color={styles.deepGreen}>
          Client
        </Typography>
        <Typography variant="h2" align="center" color={styles.deepGreen}>
          Testimonials
        </Typography>
      </div>
      <CustomGridCarousel
        itemsArray={cards}
        responsiveSizes={responsiveSizes}
        paddingBottom={2}
        speed={500}
      />
    </FanGoBody>
  );
};

export default ClientTestimonialsSection;
