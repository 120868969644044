import React from 'react';

import { OndemandVideo } from '@mui/icons-material';
import { Box } from '@mui/material';

import DemoButton from '../../common/button/DemoButton';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';
import FanGoTimeline from './FanGoTimeline';

const buttonStyle = {
  textAlign: styles.alignStyleCCCC,
  mt: 10,
};

const HowWeBuildContent = ({ data }) => {
  const { title, howWeBuild, cta } = data;

  const buttonName = (
    <MainText
      textVariant="h5"
      value={cta}
      paddingX={styles.allZero}
      marginY={styles.allZero}
    />
  );

  return (
    <Box>
      <MainText textVariant="h2" value={title} marginY={styles.allThree} />

      <FanGoTimeline data={howWeBuild} />

      <Box sx={buttonStyle}>
        <DemoButton
          buttonName={buttonName}
          startIcon={<OndemandVideo style={{ fontSize: 30 }} />}
        />
      </Box>
    </Box>
  );
};

export default HowWeBuildContent;
