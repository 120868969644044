import { Box, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import FanGoBody from '../../common/body/FanGoBody';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import DemoFormHandler from '../../form/DemoFormHandler';
import TitleSubtitleIconList from './TitleSubtitleIconList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navSim: {
      height: '70px',
      backgroundColor: theme.palette.primary.main,
    },
    horizontalContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    optionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2),
    },
    horizontalCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    demoFormContainer: {
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.5)',
    },
    verticallySpacesContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(3),
    },
  })
);

const DemoRequestTopSection = ({
  titleSubtitle,
  sectionImage,
  sectionOneList,
  mobileScreen,
  formData,
}) => {
  const classes = useStyles();
  const { title, _rawBody: subTitle } = titleSubtitle;

  return (
    <FanGoBody>
      <Grid
        container
        sx={{ flexDirection: mobileScreen ? 'column-reverse' : 'row' }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            paddingRight: mobileScreen ? 0 : 10,
            paddingTop: mobileScreen ? 3 : 0,
          }}
        >
          <div className={classes.verticallySpacesContainer}>
            <TitleSubtitleIconList
              title={title}
              subTitle={subTitle}
              list={sectionOneList}
              iconName="circleTick"
              mobileScreen={mobileScreen}
            />
            <GatsbyImage
              alt={sectionImage?.altText || ''}
              image={sectionImage?.asset?.gatsbyImageData}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.demoFormContainer}>
            <Box sx={{ pl: 3, pr: 3 }}>
              <MainText
                textVariant="h4"
                fontColor={styles.deepGreen}
                textAlignment={styles.alignStyleCCCC}
                paddingX={styles.allZero}
                paddingY={styles.allOne}
                marginY={styles.allZero}
                value={formData.title}
                fontWeight={500}
              />
              <SubText
                variant="body2"
                fontColor="grey.900"
                marginY={styles.allZero}
                textAlignment={styles.alignStyleCCCC}
                value={formData.subTitle}
              />

              {/* <Divider sx={{ backgroundColor: styles.deepGreen }} /> */}

              <div>
                <DemoFormHandler formData={formData} />
              </div>
            </Box>
          </div>
        </Grid>
      </Grid>
    </FanGoBody>
  );
};

export default DemoRequestTopSection;
